import * as styles from './youtube.module.scss'

import React, { useState } from 'react'

import Container from '../container'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getField } from '../../helper.js'
import { graphql } from 'gatsby'

const YoutubePlayer = ({ preview, titleDe='Title is missing', linkDe, linkEn, locale }) => {
  const [showVideo, setShowVideo] = useState(false)

  const previewImage = getField(preview, "file", locale)
  const previewTitle = getField(preview, "title", locale)
  const link = getField({ linkDe, linkEn }, "link", locale)

  if (!showVideo) {
    return (
      <section>
        <Container>
          <div className={styles.placeholder} onClick={() => setShowVideo(true)} onKeyDown={() => setShowVideo(true)} role="button" tabIndex={0}>
            <div className={styles.button}>
              <i className='fab fa-youtube'></i>
            </div>
            {( previewImage && previewImage.gatsbyImageData &&
              <GatsbyImage image={previewImage.gatsbyImageData} alt={previewTitle}/>
            )}
          </div>
        </Container>
      </section>
    )
  }

  let videoCode = link.split('?v=')[1]
  if (/youtu.be\/(\S*)/.test(link)) {
    videoCode = link.split('.be/')[1]
  }

  videoCode.replace('&feature=youtu.be', '')

  return (
    <section>
      <Container>
        <div className={styles.wrapper}>
          <iframe title={titleDe} width="560" height="315" src={"https://www.youtube.com/embed/" + videoCode} frameBorder="0" allow="autoplay encrypted-media" allowFullScreen></iframe>
        </div>
      </Container>
    </section>
  )
}

export default YoutubePlayer

export const query = graphql`
  fragment YoutubePlayeCoverImage on CMSUpload {
    gatsbyImageData (
      aspectRatio: 1.777777
      fit: "thumb"
      placeholder: DOMINANT_COLOR
      width: 1100
    )
  }

  fragment YoutubePlayer on CMSYoutubeVideo {
    __typename
    id
    titleDe
    linkDe
    linkEn
    preview: coverImage {
      id
      titleDe
      titleEn
      fileDe {
        ...YoutubePlayeCoverImage
      }
      fileEn {
        ...YoutubePlayeCoverImage
      }
    }
  }
`
