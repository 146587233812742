import * as styles from './grid.module.scss'

import { Link, graphql } from 'gatsby'

import Container from '../container'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { getField } from '../../helper.js'
import { pathTo } from '../../routes'

function CategoriesGrid({ categories=[], locale }) {
  return (
    <section>
      <Container>
        <div className={styles.categories}>
          {categories.map(category => {
            const title = getField(category, "title", locale)
            const image = getField(category.image, "file", locale)

            return (
              <div key={category.id} className={styles.category}>
                <Link to={pathTo({ ...category, locale })}>
                  {( image?.gatsbyImageData &&
                    <GatsbyImage
                      image={image?.gatsbyImageData}
                      alt={title}
                    />
                  )}
                </Link>
              </div>
            )
          })}
        </div>
      </Container>
    </section>
  )
}

export default CategoriesGrid

export const query = graphql`
  fragment CategoriesGrid on CMSCategoryCollection {
    categories {
      __typename
      id
      titleDe
      titleEn
      slugDe
      slugEn
      image: coverImage {
        titleDe
        titleEn
        fileDe {
          gatsbyImageData(
            height: 230
            quality: 65
            placeholder: DOMINANT_COLOR
            width: 350
          )
        }
        fileEn {
          gatsbyImageData(
            height: 230
            quality: 65
            placeholder: DOMINANT_COLOR
            width: 350
          )
        }
      }
    }
  }
`
