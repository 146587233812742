import * as styles from './index.module.scss'

import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image'

import { graphql } from 'gatsby'
import React from "react"
import { getField } from '../../helper.js'
import BannerNewsletter from './newsletter'

const Banner = (banner) => {
  const { imageBanner = false, locale } = banner
  const title = getField(banner, "title", locale)
  const link = getField(banner, "link", locale)
  const visualMobile = getField(banner.visualMobile, "file", locale)
  const visualMedium = getField(banner.visualMedium, "file", locale)
  const visualNewsletter = getField(banner.visualNewsletter, "file", locale)

  if (title === 'Newsletter') {
    return <BannerNewsletter locale={locale} visual={visualNewsletter} />
  }

  if (visualMobile === undefined || visualMedium === undefined) return null

  const images = withArtDirection(visualMobile?.gatsbyImageData, [
    {
      media: "(min-width: 768px)",
      image: visualMedium?.gatsbyImageData
    }
  ])

  return (
    <section className={styles.wrapper}>
      <a href={link} target='_blank' className={styles.banner} rel="noopener noreferrer" data-regular-banner={!imageBanner}>
        {(!imageBanner &&
          <div className={styles.text}>{title}</div>
        )}
        <div className={styles.visual}>
          {(visualMobile?.gatsbyImageData && visualMedium?.gatsbyImageData &&
            <GatsbyImage
              image={images}
              alt={title}
              className={styles.artDirected}
            />
          )}
        </div>
      </a>
    </section>
  )
}

export default Banner

export const query = graphql`
  fragment BannerVisualMobile on CMSUpload {
    gatsbyImageData(
      fit: "thumb"
      placeholder: DOMINANT_COLOR
      sizes: "(min-width: 1000px) 2220px, (min-width: 786px) 1110px, (min-width: 375px) 375px, 100vw"
      quality: 75
      width: 375
    )
  }

  fragment BannerVisualDesktop on CMSUpload {
    gatsbyImageData(
      fit: "thumb"
      height: 220
      placeholder: DOMINANT_COLOR
      quality: 75
      width: 1110
    )
  }

  fragment BannerVisualNewsletter on CMSUpload {
    src
  }

  fragment Banner on CMSBanner {
    id
    titleDe
    titleEn
    linkDe
    linkEn
    imageBanner

    visualMobile: mobileVisual {
      fileDe {
        ...BannerVisualMobile
      }
      fileEn {
        ...BannerVisualMobile
      }
    }

    visualMedium: desktopVisual {
      fileDe {
        ...BannerVisualDesktop
      }
      fileEn {
        ...BannerVisualDesktop
      }
    }

    visualNewsletter: desktopVisual {
      fileDe {
        ...BannerVisualNewsletter
      }
      fileEn {
        ...BannerVisualNewsletter
      }
    }
  }
`

// TODO: fix newsletter banner
// fluid(maxWidth: 2000, maxHeight: 1200) {
//   ...GatsbyContentfulFluid_withWebp
// }
