// TODO: use headlines with border
// TODO: recipe headline 18px (1.05em)

import * as styles from './preview.module.scss'

import { Link } from 'gatsby'
import React from "react"
import { getField } from '../../helper.js'
import { pathTo } from '../../routes'
import { translate } from '../../translations'
import Container from '../container'
import HeadlineMain from '../headline/main'
import PostsGrid from '../posts/grid'

const SeriesPreview = ({ series, locale }) => {
  if (!series.posts || series.posts.edges.length === 0) return null
  const previewPosts = [...series.posts.edges.map(node => node.node)].filter(p => p).slice(0, 5)
  const index = previewPosts.length > 0 ? previewPosts.length - 1 : 0
  if (index === 0) return null
  const previewPostImage = previewPosts[index].image

  const seriesPreview = {
    __typename: 'CMSSeries',
    id: series.id,
    titleDe: "Alle Artikel",
    titleEn: "See All Articles",
    image: previewPostImage,
    slugDe: series.slugDe,
    slugEn: series.slugEn,
    locale,
    gateway: true
  }

  const posts = [
    ...series.posts.edges.map(node => node.node).slice(0, 5),
    seriesPreview
  ]
  const title = getField(series, "title", locale)

  return (
    <div className={styles.series}>
      <Container>
        <HeadlineMain
          headline={title}
          actions={(
            <Link to={pathTo({ ...series, locale })}>{translate('general.showAll', series.locale)}</Link>
          )}
        />
      </Container>
      <PostsGrid posts={posts} locale={locale} />
    </div>
  )
}

export default SeriesPreview
