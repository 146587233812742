import * as styles from './index.module.scss'

import Container from '../container'
import React from 'react'
import TextRenderer from './renderer'
import { graphql } from 'gatsby'

const Text = ({ textDe, textEn, textAlignment, locale }) => {
  const alignment = textAlignment ? textAlignment : 'left'
  const text = locale === 'de' ? textDe : textEn
  const columnsRenderer = (props) => {
    if (props.skipHtml) {
      return null
    }

    if (props.value === '<div class="column-break"></div>') {
      return React.createElement('div', {'data-column': 'break'})
    }
  }

  return (
    <Container>
      <div className={styles.text} data-alignment={alignment.toLowerCase()}>
        <TextRenderer text={text} renderers={{ html: columnsRenderer }}/>
      </div>
    </Container>
  )
}

export default Text

export const query = graphql`
  fragment Text on CMSText {
    textAlignment
    textDe
    textEn
  }
`
