// TODO: desktop - flex ingred instructions
// TODO: check schema.org

import * as styles from './card.module.scss'

import { graphql } from 'gatsby'
import React from 'react'
import { getField } from '../../helper.js'
import { translate } from '../../translations'
import Container from '../container'
import TextRenderer from '../text/renderer'

const RecipeCard = (recipeCard) => {
  const title = getField(recipeCard, "title", recipeCard.locale)
  const ingredients = getField(recipeCard, "ingredients", recipeCard.locale)
  const instructions = getField(recipeCard, "instructions", recipeCard.locale)
  const preparationTime = getField(recipeCard, "preperationTime", recipeCard.locale)
  const servesFor = getField(recipeCard, "servesFor", recipeCard.locale)

  const ingredientsRenderer = (props) => {
    return React.createElement('li', { ...props, index: null, ordered: "false", 'itemProp': 'recipeIngredient', tight: null })
  }

  return (
    <section className={styles.card}>
      <Container>
        <h2 className={styles.title}>
          {title}
        </h2>
        <div className={styles.infos}>
          {(servesFor &&
            <div itemProp="recipeYield">{servesFor}</div>
          )}
          {(preparationTime &&
            <div>{preparationTime}</div>
          )}
        </div>
        <div className={styles.sections}>
          <div className={styles.section}>
            <h3 className={styles.headline}>{translate('recipe.ingredients', recipeCard.locale)}</h3>
            <div className={styles.ingredients}>
              <TextRenderer text={ingredients} renderers={{ listItem: ingredientsRenderer }} />
            </div>
          </div>
          <div className={styles.section}>
            <h3 className={styles.headline}>{translate('recipe.instructions', recipeCard.locale)}</h3>
            <div className={styles.instructions} itemProp="recipeInstructions">
              <TextRenderer text={instructions} />
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default RecipeCard

export const query = graphql`
  fragment RecipeCard on CMSRecipeCard {
    id
    titleDe
    titleEn
    ingredientsDe
    ingredientsEn
    instructionsDe
    instructionsEn
    preperationTimeDe
    preperationTimeEn
    servesForDe
    servesForEn
  }
`
