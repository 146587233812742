import "@fontsource/playfair-display/700.css"
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import React, { useMemo } from 'react'
import Container from '../../components/container'
import { pathTo } from '../../routes'
import { _teaser, body, grid, header, headerEmpty, image, item, title, wrapper } from './index.module.scss'

export default function CardGrid({ locale, items = [] }) {
  return useMemo(() => {
    if (locale === 'en') return null
    // get todays date
    const today = new Date()

    return (
      <section className={wrapper}>
        <Container>
          <div className={grid}>
            {items.map((_item, index) => {
              const Tag = _item?.url ? 'a' : Link
              const date = new Date(2023, 0, index + 1)
              const hasImage = _item?.coverImage?.fileDe



              // compare dates if _item.visibleFromDatetime is in the future
              // parse _item.visibleFromDatetime to date and check if its greater than today
              const visibleFromDatetime = _item.visibleFromDatetime ? new Date(_item.visibleFromDatetime) : date
              if (visibleFromDatetime > today) {
                return (
                  <div key={_item.identifier} className={item}>
                    <div className={[header, headerEmpty].join(" ")}>
                      {_item?.previewImage?.fileDe && (
                        <div className={image}>
                          <GatsbyImage image={_item?.previewImage?.fileDe?.gatsbyImageData} />
                        </div>
                      )}
                    </div>
                    {_item.sublineDe && (
                      <div className={body}>
                        <div className={_teaser}>
                          {_item.sublineDe}
                        </div>
                      </div>
                    )}
                  </div>
                )
              }

              return (
                <Tag to={pathTo({ ..._item?.record, locale: "de" })} href={_item.url} key={_item.identifier} className={item}>
                  <div className={header} style={{ backgroundColor: _item?.categoryAsJson?.color }}>
                    {hasImage && (
                      <div className={image}>
                        <GatsbyImage image={_item?.coverImage?.fileDe?.gatsbyImageData} />
                      </div>
                    )}
                  </div>
                  {(_item?.titleDe && _item?.teaser) && (
                    <div className={body}>
                      {_item?.titleDe && <div className={title}>{_item?.titleDe}</div>}
                      {_item?.teaser && (
                        <div className={_teaser}>
                          {_item?.teaser}
                        </div>
                      )}
                    </div>
                  )}
                </Tag>
              )
            })}
          </div>
        </Container>
      </section>
    )
  }, [items, locale])
}

export const query = graphql`
  fragment CardGrid on CMSCardGrid {
    __typename
    id
    items {
      ...on CMSCardGridItem {
        identifier: id
        titleDe
        sublineDe
        teaser
        url
        categoryAsJson
        visibleFromDatetime
        record {
          __typename
          ...on CMSRecipe {
            id
            slugDe
          }
          ...on CMSPost {
            id
            slugDe
            series {
              id
              slugDe
              slugEn
            }
          }
        }
        coverImage {
          titleDe
          fileDe {
            gatsbyImageData(
              aspectRatio: 1.6611111111
              width: 384
            )
          }
        }
        previewImage {
          titleDe
          fileDe {
            gatsbyImageData(
              aspectRatio: 1.6611111111
              width: 384
            )
          }
        }
      }
    }
  }
`
