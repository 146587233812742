// extracted by mini-css-extract-plugin
export var wrapper = "index-module--wrapper--6Yvon";
export var grid = "index-module--grid--SJT5T";
export var item = "index-module--item--AmCvf";
export var header = "index-module--header--bT0uS";
export var headerEmpty = "index-module--headerEmpty--0zZuv";
export var headline = "index-module--headline--bg-CG";
export var image = "index-module--image--Y0f7V";
export var circle = "index-module--circle--8QfsW";
export var body = "index-module--body--3Yucm";
export var _teaser = "index-module--_teaser--ZlbKw";
export var title = "index-module--title--tOWdq";
export var fadein = "index-module--fadein--6uGfN";