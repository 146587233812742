// TODO: add support for videos
import * as styles from './index.module.scss'

import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from "react"
import { getField } from '../../helper.js'
import Container from '../container'

const Photos = ({ photos = [], locale }) => {
  if (photos === null || photos.length === 0) return null

  return (
    <Container>
      <div className={styles.photos}>
        {(photos.map((photo, index) => {
          const file = getField(photo, "file", locale)
          const title = getField(photo, "title", locale)
          if (!file || !file.gatsbyImageData) return null

          return (
            <div key={`${photo.id}${index}`} className={styles.photo}>
              <GatsbyImage
                image={file?.gatsbyImageData}
                alt={title}
              />
            </div>
          )
        }))}
      </div>
    </Container>
  )
}

export default Photos

export const query = graphql`
  fragment PhotoData on CMSUpload {
    src
    mimeType
    gatsbyImageData(
      placeholder: DOMINANT_COLOR
      quality: 65
      width: 1110
    )
  }
  fragment Photos on CMSPhotoCollection {
    id
    photos {
      titleDe
      titleEn
      fileDe {
        ...PhotoData
      }
      fileEn {
        ...PhotoData
      }
    }
  }
`
