import * as styles from './carousel.module.scss';

import React, { useRef } from "react";

import PostCard from '../post/card';

const PostsCarousel = ({ posts=[], locale }) => {
  const carousel = useRef(null);
  const items = posts.map(post => {
    if (post === null) return null
    return <PostCard key={post.id} post={post} locale={locale}/>
  })

  const scrollToNext = (direction) => {
    const current = carousel.current;

    if (current) {
      const children = current.childNodes;
      if (children.length === 0) return;
      const width = children[0].getBoundingClientRect().width;

      current.scrollTo({
        left: current.scrollLeft + (width * direction),
        behavior: 'smooth'
      });
    }
  }

  if (!posts || posts.length === 0) return <div className={styles.empty}>Keine Beiträge gefunden</div>;
  return (
    <div className={styles.wrapper}>
      <button className={styles.button} data-direction='left' onClick={() => scrollToNext(-1)}>
        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512">
          <path fill="currentColor" d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path>
        </svg>
      </button>
      <button className={styles.button} data-direction='right' onClick={() => scrollToNext(1)}>
        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path></svg>
      </button>
      <div className={styles.carousel} ref={carousel}>
        {items}
      </div>
    </div>
  )
};

export default PostsCarousel;
