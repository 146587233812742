import React from "react";
import { graphql } from 'gatsby';

const HtmlCode = ({ code }) => {
  if (!code) return null
  return (
    <section dangerouslySetInnerHTML={{ __html: code }}/>
  )
};

export default HtmlCode

export const query = graphql`
  fragment HtmlCode on CMSHtmlCode {
    __typename
    id
    code
  }
`;
