import * as styles from './newsletter.module.scss'

import React, { useState } from "react"

import HeadlineMain from '../headline/main'
import { translate } from '../../translations'

const BannerNewsletter = ({ locale, visual }) => {
  const [focus, setFocus] = useState(false)
  const visualUrl = `${visual.src}?w=2200&q=45&fit=thumb`

  return (
    <section>
      <div className={styles.container}>
        <div className={styles.banner}>
          <div className={styles.content}>
            <HeadlineMain headline={translate('newsletter.headline', locale)} color='white' useBorder={false}/>
            <div className={styles.copy}>{translate('newsletter.copy', locale)}</div>
            <form action='https://zuckerjagdwurst.us12.list-manage.com/subscribe/post?u=3cac6a90c452353360d243132&id=349c44c381' method='post' target='_blank' noValidate>
              <input className={styles.input} type="email" name="EMAIL" id="mce-EMAIL" onFocus={() => setFocus(true)} placeholder='Email'/>
              <button className={styles.button} type="submit">{translate('newsletter.cta', locale)}</button>
            </form>
            {( focus &&
              <div className={styles.legal}>
                <p><b>Erlaubnis zum Marketing</b></p>
                <p>Wir schicken dir monatlich kostenfrei neue Rezepte und Inspirationen direkt in dein Postfach.</p>
                <label htmlFor="gdpr_12937">
                  <input id="gdpr_12937" type="checkbox" name="gdpr[12937]" value="Y"/>
                  Ja, ich will den Newsletter abonnieren. (bitte ankreuzen!)
                </label>
                <p>Du kannst unseren Newsletter jederzeit abbestellen, indem du auf den Abbestellungs-Link in der Fußzeile jeder E-Mail klickst. Mit der Anmeldung zum Newsletter erklärst du dich damit einverstanden, dass wir die Informationen in Übereinstimmung mit diesen Bedingungen verarbeiten dürfen. Deinen Namen benötigen wir zwecks Personalisierung.</p>
              </div>
            )}
          </div>
          <div className={styles.visual} style={{ backgroundImage: `url(${visualUrl})` }}/>
        </div>
      </div>
    </section>
  )
}

export default BannerNewsletter
