import { graphql } from 'gatsby'
import React from "react"
import SeriesPreview from './preview'

const SeriesListing = ({ series = [], locale }) => {
  return (
    <section>
      {(series.map(series => <SeriesPreview key={series.id} series={series} locale={locale} />))}
    </section>
  )
}

export default SeriesListing

// TODO: add limit to posts
export const query = graphql`
  fragment SeriesListing on CMSSeriesCollection {
    __typename
    id
    series {
      __typename
      id
      titleDe
      titleEn
      slugDe
      slugEn
      image: coverImage {
        ...TileImage
      }
      posts {
        edges {
          node {
            ...PostTile
          }
        }
      }
    }
  }
`

// TODO: rename component to SeriesCollection
// TODO: what about the moreLabel?
