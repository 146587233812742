import { graphql } from 'gatsby'
import React from 'react'
import { getField } from '../../helper.js'
import Container from '../container'
import HeadlineMain from '../headline/main'
import PostsGrid from '../posts/grid'
import Promo from '../promo'

const PostAndRecipeGrid = ({ titleDe, titleEn, items, showAsCarousel = false, locale }) => {
  const title = getField({ titleDe, titleEn }, "title", locale)

  if (showAsCarousel) {
    return <Promo titleDe={titleDe} titleEn={titleEn} items={items} locale={locale} />
  }

  if (!showAsCarousel) {
    // fix this actually in the cms
    if (!items) return null

    const updatesImageItems = items.map(item => {
      return {
        ...item,
        image: item.tileImage,
        tileImage: undefined
      }
    })

    return (
      <section>
        <Container>
          <div>
            <HeadlineMain headline={title} />
          </div>
        </Container>
        <PostsGrid posts={updatesImageItems} locale={locale} />
      </section>
    )
  }

  return null
}

export default PostAndRecipeGrid

export const query = graphql`
  fragment PostAndRecipeGrid on CMSRecipePostCollection {
    titleDe
    titleEn
    showAsCarousel
    items {
      __typename
      ... on CMSPost {
        ...PromoPost
      }
      ... on CMSRecipe {
        ...PromoRecipe
      }
    }
  }
`
