import * as styles from './main.module.scss'

import React from "react"
import TextBorder from '../text/border'

const HeadlineMain = ({ headline = 'Please add a headline', actions, color = 'black', useBorder = true }) => {
  return (
    <div className={styles.wrapper} data-has-actions={actions !== undefined}>
      <h2 className={styles.headline} style={{ color }}>
        {useBorder ? <TextBorder text={headline} color={color} /> : headline}
      </h2>
      {(actions &&
        <div className={styles.actions}>{actions}</div>
      )}
    </div>
  )
}

export default HeadlineMain
