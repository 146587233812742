import * as styles from './renderer.module.scss'

import React, { Component } from 'react'

import PropTypes from 'prop-types'
import Banner from '../banner'
import CardGrid from '../card-grid'
import CategoriesGrid from '../categories/grid'
import YoutubePlayer from '../embeds/youtube'
import HtmlCode from '../html-code'
import Photos from '../photos'
import PostAndRecipeGrid from '../postAndRecipeGrid'
import Promo from '../promo'
import RecipeCard from '../recipe/card'
import SeriesListing from '../series/listing'
import Text from '../text'

class SectionsRenderer extends Component {
  render() {
    const { sections, locale } = this.props

    if (!sections || sections.length === 0) { return null }

    const renderedSections = sections.map((section, index) => {
      const key = index

      section['locale'] = locale

      switch (section.__typename) {
        case 'CMSBanner':
          return <Banner key={key} {...section} />
        case 'CMSCardGrid':
          return <CardGrid key={key} {...section} />
        case 'CMSHtmlCode':
          return <HtmlCode key={key} {...section} />
        case 'CMSPhotoCollection':
          return <Photos key={key} {...section} />
        case 'CMSPromo':
          return <Promo key={key} {...section} />
        case 'CMSRecipePostCollection':
          return <PostAndRecipeGrid key={key} {...section} showAsCarousel={!!section.showAsCarousel} />
        case 'CMSCategoryCollection':
          return <CategoriesGrid key={key} {...section} />
        case 'CMSRecipeCard':
          return <RecipeCard key={key} {...section} />
        case 'CMSSeriesCollection':
          return <SeriesListing key={key} {...section} />
        case 'CMSText':
          return <Text key={key} {...section} />
        case 'CMSYoutubeVideo':
          return <YoutubePlayer key={key} {...section} />
        default:
          return null
      }
    })

    return (
      <div className={styles.renderer}>
        {renderedSections}
      </div>
    )
  }
}

SectionsRenderer.propTypes = {
  sections: PropTypes.array.isRequired
}

SectionsRenderer.defaultProps = {
  sections: []
}

export default SectionsRenderer
